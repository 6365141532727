@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins';
}

html {
  overflow-y: hidden;
}

body {
  background-color: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior: none;
  height: -webkit-fill-available;
  text-rendering: optimizeSpeed;
  position: relative;
  scroll-snap-type: y mandatory;
  place-items: center;
}



.user_logout {
  position: absolute;
  padding: 4px;
  top: 10px;
  right: 20px;
  z-index: 10;
  background: white;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
* {
  margin: 0;
  padding: 0;
}

.tags {
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
}

.tag {
  padding: 7px 10px;
  background: rgba(255,255,255,0.2);
  color: rgba(255,255,255,0.8);
  font-weight: 800;
  font-size: 10px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

.root, .App {
  width: 100%;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  scroll-snap-align: start;
}

.App img {
  width: 100%;
  object-fit: cover;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
}

.App .overlay {
  width: 100%;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, rgba(0,0,0,0.80) 90%);
  position: absolute;
  top: 0;
  left: 0;
}

.App .text {
  position: absolute;
  bottom: 80px;
  left: 0;
  width: 100%;
  padding: 10px 20px;
}

.App .text h2 {
  color: white;
  padding-bottom: 10px;
}

.App .text p {
  color: white;
  font-size: 14px;
  width: 80%;
}

.App .text .svg {
  position: absolute;
  top: 10px;
  right: 20px;
  opacity: 0.7;
}

.App .studio_div {
  padding-top: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.App .studio_div .span {
  color: white;
  font-size: 12px;
}

.App .studio_div .studio {
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px
}

.App .studio_div .studio img {
  width: 25px;
  height: 25px;
}

.App .studio_div .studio span {
  color: white;
  font-weight: 500;
  font-size: 14px;
}

.studio_followbtn_borded {
  background: transparent;
  border-radius: 51px;
  padding: 10px 16px;
  color: white;
  border: 0.4px solid white;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}
.bottomBar {
    position: fixed;
    width: 100vw;
    height: 70px;
    background-color: rgba(0,0,0,0.8);
    z-index: 999;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    align-items: center;
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
}

.bottomBar img {
    width: 35px;
    height: 35px;
}
.Home {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(/static/media/home_prueba.68440aa9.png);
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    color: white;
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.Home_Inner {
    padding: 0 18vw 6vh;
}

.Home_Studio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    grid-gap: 6px;
    gap: 6px;
}

.Home_Studio img {
    height: 40px;
    width: 40px;
}

.Home_Studio p {
    font-weight: 500 !important;
    font-size: 16px !important;
}

.Home_Inner h2 {
    font-weight: 700;
    font-size: 100px;
    line-height: 100%;
}

.Home_Inner p {
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
}

.Home_Tags {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.Home_Tags p {
    font-weight: 500;
    font-size: 14px;
    padding: 16px 30px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 35px;
}

.Home_Bottom {
    width: 100%;
    padding: 0 6vw;
    position: absolute;
    bottom: 50px;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    grid-gap: 6vw;
    gap: 6vw;
}

.Home_Bottom_Nav {
    display: flex;
    flex-direction: row;
    grid-gap: 4px;
    gap: 4px;
}

.Home_Bottom_Friends {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.Home_Bottom_Friends_Up {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.Home_Bottom_Friends_Up p {
    font-weight: 500;
    font-size: 16px;
}

.Home_Bottom_Friends_Down {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.Home_Bottom_Friends_Down img {
    width: 65px;
    height: 65px;
    border: 2px solid #30F998;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.Home_Play_Div {
    width: 100%;
    text-align: right;
}

.Home_Play {
    font-weight: 600;
    font-size: 14px;
    color: black;
    padding: 20px 30px;
    background: #FFFFFF;
    border-radius: 15px;
    border: 0;
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 20px 6vw;
}

.header_logo {
    width: 140px;
}

.header_user {
    width: 52px;
    height: 52px;
    border: 2px solid #30F998;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}
* {
    margin: 0;
    padding: 0;
}

.userDiv {
    min-height: calc(1vh * 100);
    min-height: calc(var(--vh, 1vh) * 100);
    background-image: url(/static/media/grad_bckgrnd.8ac4b917.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.user_background {
    height: 180px;
    width: 102%;
    position: relative;
    z-index: 1;
    object-fit: cover;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    left: -2px;
}

.user_info {
    text-align: center;
    position: relative;
    width: 100%;
    z-index: 10;
    margin-top: -50px;

}

.user_profile {
    width: 100px;
    height: 100px;
    box-shadow: 0px 0px 0px 3px #30F998;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.user_info h3 {
    color: white;
    padding-top: 10px;
}

.user_info h6 {
    color: white;
    opacity: 0.8;
    font-weight: 300;
    font-size: 12px;
}

.user_info p {
    color: white;
    padding-top: 10px;
    font-weight: 400;
    font-size: 14px;
    width: 80%;
    margin: auto;
}

.user_friends {
    display: flex;
    flex-direction: row;
    width: 100vw;
    grid-gap: 20px;
    gap: 20px;
    overflow-x: scroll;
    padding: 20px 10px;
}

.user_friends h6 {
    color: white;
    opacity: 0.8;
    font-weight: 300;
    font-size: 10px;
    text-align: center;
}

.user_friend {
    width: 76px;
    height: 76px;
    box-shadow: 0px 0px 0px 3px #30F998;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.userDiv .react-tabs__tab-list {
    padding-top: 10px;
    text-align: center;
    border: 0;
    display: flex;
    justify-content: space-around;
}

.userDiv .react-tabs__tab {
    color: white;
    opacity: 0.2;
    padding: 0;
    font-weight: 600;
    font-size: 12px;
}

.userDiv .react-tabs__tab:focus:after {
    background: #FCCA4A;
    height: 2px;
}

.userDiv .react-tabs__tab--selecte:focus:after {
    background: #FCCA4A;
    height: 2px;
}

.userDiv .react-tabs__tab--selected {
    color: #FCCA4A;
    opacity: 1;
    background: transparent;
    border: 0;
}

.react-tabs__tab-panel {
    padding: 20px 0 60px;
}

.user_favourites {
    display: flex;
    flex-direction: row;
    width: 100vw;
    grid-gap: 20px;
    gap: 20px;
    overflow-x: scroll;
    padding: 0 20px 10px;

}

.user_favourites_item {
    position: relative;
}

.user_favourites_text {
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
    padding: 18px 12px;
}

.user_favourites_text_tabs {
    display: flex;
    flex-direction: row;
    grid-gap: 4px;
    gap: 4px;
    padding-bottom: 12px;

}

.user_favourites_text h6 {
    font-weight: 400;
    padding-top: 2px;
}

.user_favourites_text_tabs small {
    color: white;
    font-size: 10px;
    font-weight: bold;
    background: rgba(255,255,255,0.3);
    padding: 4px 10px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.user_favourites_img {
    width: 60vw;
}

.user_comingsoon {
    position: relative;
    width: 100%;
    height: 24vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user_comingsoon h4 {
    color: white;
    padding-bottom: 1vh;
    z-index: 5;
    text-align: center;
    letter-spacing: 5.5px;
    text-transform: uppercase;
    font-weight: 800;
}

.user_favourites_comingsoon {
    width: 100%;
    position: absolute;
    z-index: 4;
}




/* DESKTOP */

.user_D_Div {
    font-family: 'Poppins', sans-serif;
    height: 100vh;
    width: 100%;
    background-image: url(/static/media/desktop_back.d4325f5c.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 20px;
    padding: 0 6vw;
}

.user_D_background {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}

.user_D_ProfileDiv { 
    grid-area: 2 / 1 / 4 / 3;
    text-align: center;
}

.user_D_ProfileDiv h2 {
    font-weight: 500;
    font-size: 24px;
    color: white;
    padding: 0;
    margin: 0;
    line-height: 100%;
}

.user_D_ProfileDiv small {
    font-weight: 300;
    font-size: 12px;
    color: white;
    opacity: 0.8;
}

.user_D_ProfileDiv p {
    padding-top: 10px;
    font-weight: 300;
    font-size: 14px;
    color: white;
    width: 90%;
    margin: auto;
}

.user_D_profile {
    height: 100px;
    width: 100px;
    border: 4px solid #30F998;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}
.user_D_FriendsDiv { 
    grid-area: 4 / 1 / 6 / 3;
    text-align: center;
}

.user_D_FriendsDiv h2 {
    font-weight: 500;
    font-size: 24px;
    color: white;
    padding: 0;
    margin: 0;
    line-height: 100%;
}

.user_D_FriendsDiv_Row {
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    grid-gap: 24px;
    gap: 24px;
    padding-top: 16px;
}

.user_D_FriendsDiv_Row_Div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;

}

.user_D_FriendsDiv_Row_Div small {
    font-weight: 300;
    font-size: 12px;
    color: white;
    opacity: 0.8;
}

.user_D_FriendsDiv_Row_Div img {
    height: 60px;
    width: 60px;
    border: 2px solid #30F998;
    border-radius: 70px;
    -webkit-border-radius: 70px;
    -moz-border-radius: 70px;
    -ms-border-radius: 70px;
    -o-border-radius: 70px;
}

.user_D_DashDiv { 
    grid-area: 2 / 3 / 6 / 7;
    padding: 30px 0 30px 30px;
}

.user_D_DashDiv h2 {
    font-weight: 500;
    font-size: 24px;
    color: white;
    padding: 0;
    margin: 0;
    line-height: 100%;
}

.user_D_DashDiv_Carousel {
    padding-top: 20px;
}

.user_D_DashDiv_SplideDiv {
    width: 22vw;
    aspect-ratio: 16 / 9;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 16px;
    color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.user_D_DashDiv_SplideDiv h3 {
    font-weight: 600;
    font-size: 18px;
}

.user_D_DashDiv_SplideDiv h4 {
    font-weight: 400;
    font-size: 12px;
}

.user_D_DashDiv_SplideDiv_Tags {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.user_D_DashDiv_SplideDiv_Tag {
    font-weight: 400;
    font-size: 10px;
    padding: 5px 12px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 23.9937px;
    letter-spacing: 0.6px;
}

.user_D_DashDiv_Activity {
    padding-top: 30px;
}

.user_D_DashDiv_ActivityRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 40px;
    gap: 40px;
    padding-right: 30px;
    padding-top: 10px;
}

.user_D_DashDiv_ActivityRow_Div {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    width: 100%;
    padding: 16px 22px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    position: relative;
}

.user_D_DashDiv_ActivityRow_Div img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 70px;
    -webkit-border-radius: 70px;
    -moz-border-radius: 70px;
    -ms-border-radius: 70px;
    -o-border-radius: 70px;
}

.user_D_DashDiv_ActivityRow_Div_Right h3 {
    font-weight: 600;
    font-size: 14px;
}

.user_D_DashDiv_ActivityRow_Div_Right p {
    font-weight: 300;
    font-size: 12px;
}

.user_D_DashDiv_ActivityRow_Div b {
    font-weight: 700;
    font-size: 14px;
    position: absolute;
    top: 10px;
    right: 18px;
    color: #FFB800;
}
* {
    margin: 0;
    padding: 0;
}

.gameDiv {
    min-height: calc(1vh * 100);
    min-height: calc(var(--vh, 1vh) * 100);
    background-image: url(/static/media/grad_bckgrnd.8ac4b917.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.game_background {
    height: 42vh;
    width: 100%;
    position: relative;
    z-index: 9;
    object-fit: cover;
    object-position: bottom;
}

.gameBack {
    position: relative;
}

.gameBack .gameback_overlay {
    position: absolute;
    width: 100%;
    height: 99%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 10%, #000000 100%);
    top: 0;
    left: 0;
    z-index: 10;
}

.gameBack_inner {
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 12;
}

.gameBack_inner h2 {
    color: white;
    padding-bottom: 10px;
}

.gameBack_inner p {
  color: white;
  font-size: 14px;
  width: 80%;
}

.gameBack_inner .svg {
    position: absolute;
    top: 10px;
    right: 20px;
    opacity: 0.7;
  }
  
  .gameBack_inner .studio_div {
    padding-top: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 15px;
  }
  
  .gameBack_inner .studio_div .span {
    color: white;
    font-size: 12px;
  }
  
  .gameBack_inner .studio_div .studio {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px
  }
  
  .gameBack_inner .studio_div .studio img {
    width: 35px;
    height: 35px;
  }
  
  .gameBack_inner .studio_div .studio span {
    color: white;
    font-weight: 600;
  }

.game_playbtn {
    background: linear-gradient(91.32deg, rgba(100, 43, 115, 0.9) 0%, rgba(198, 66, 110, 0.9) 100%);
    border-radius: 51px;
    padding: 10px 36px;
    color: white;
    border: none;
    font-weight: 500;
    font-size: 14px;
}

.game_bottom {
    background: black;
    padding: 20px 20px 30px;
    margin-top: -6px;
    position: relative;
    z-index: 20;
    border-radius: 0px 0px 30px 30px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.game_stats {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}

.game_stat {
    text-align: center;
}

.game_stat b {
    color: white;
    font-weight: 600;
    font-size: 18px;
}

.game_stat h4 {
    color: white;
    font-weight: 400;
    font-size: 12px;

}

.game_social {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 15px;
}

.game_socialfriends {
    padding: 0 10px 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.game_socialfriends img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    object-position: center;
    margin-left: -20px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.game_social p {
    color: white;
    font-size: 14px;
}

.game_leaderboard {
    padding: 30px 24px 80px;
}

.game_leaderboard h2 {
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: 22px;
}

.game_leaderboard_first {
    padding: 20px 0;
    text-align: center;
}

.game_leaderboard_first_img {
    width: 68px;
    height: 68px;
    border-radius: 70px;
    -webkit-border-radius: 70px;
    -moz-border-radius: 70px;
    -ms-border-radius: 70px;
    -o-border-radius: 70px;
}

.game_leaderboard_first_usertag {
    font-weight: 300;
    font-size: 14px;
    color: white;
}

.game_leaderboard_first_score {
    font-weight: 600;
    font-size: 18px;
    color: #30F998;

}

.game_leaderboard_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 10px;
}

.game_leaderboard_div small {
    font-weight: 600;
    font-size: 16px;
    color: white;
}

.game_leaderboard_div_inner {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    grid-gap: 12px;
    gap: 12px;
    align-items: center;
    flex-direction: row;
    background: rgba(217, 217, 217, 0.1);
    border-radius: 49px;
    padding: 14px;

}

.game_leaderboard_div_inner small {
    font-weight: 400;
    font-size: 13px;
    color: white;
}

.game_leaderboard_div_inner_score {
    font-weight: 600;
    font-size: 18px;
    color: #30F998;
    width: 100%;
    text-align: right;
    padding-right: 10px;
}


.game_leaderboard_div_inner_user {
    width: 40px;
    height: 40px !important;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
}
* {
    margin: 0;
    padding: 0;
}

.studioDiv {
    min-height: calc(1vh * 100);
    min-height: calc(var(--vh, 1vh) * 100);
    background-image: url(/static/media/grad_bckgrnd.8ac4b917.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.studio_background {
    height: 38vh;
    width: 100%;
    position: relative;
    z-index: 9;
    object-fit: cover;
    object-position: bottom;
}

.studioBack {
    position: relative;
}

.studioBack .studioback_overlay {
    position: absolute;
    width: 100%;
    height: 99%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 10%, #000000 100%);
    top: 0;
    left: 0;
    z-index: 10;
}

.studioBack_inner {
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 12;
}

.studioBack_inner h2 {
    color: white;
    padding-bottom: 10px;
}

.studioBack_inner p {
  color: white;
  font-size: 14px;
  width: 80%;
  padding-bottom: 12px;
}

.studioBack_inner .svg {
    position: absolute;
    top: 10px;
    right: 20px;
    opacity: 0.7;
  }
  
  .studioBack_inner .studio_div {
    padding-top: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
  }
  
  .studioBack_inner .studio_div .span {
    color: white;
    font-size: 12px;
  }
  
  .studioBack_inner .studio_div .studio {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px
  }
  
  .studioBack_inner .studio_div .studio img {
    width: 35px;
    height: 35px;
  }
  
  .studioBack_inner .studio_div .studio span {
    color: white;
    font-weight: 600;
  }

.studio_followbtn {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 51px;
    padding: 12px 20px;
    color: white;
    border: none;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.studio_bottom {
    background: black;
    padding: 20px 20px 30px;
    margin-top: -6px;
    position: relative;
    z-index: 20;
    border-radius: 0px 0px 30px 30px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.studio_stats {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}

.studio_stat {
    text-align: center;
}

.studio_stat b {
    color: white;
    font-weight: 600;
    font-size: 18px;
}

.studio_stat h4 {
    color: white;
    font-weight: 400;
    font-size: 12px;

}

.studio_social {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 15px;
}

.studio_socialfriends {
    padding: 0 10px 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.studio_socialfriends img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    object-position: center;
    margin-left: -20px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.studio_social p {
    color: white;
    font-size: 14px;
}

.studio_carousel {
    padding: 35px 0 80px;
}
/* DESKTOP */

.signIn {
    overflow: hidden;
    font-family: 'Poppins', sans-serif;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    background-image: url(/static/media/desktop_back.d4325f5c.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signin_back {
    position: absolute;
    top: 30px;
    left: 20px;
    z-index: 9;
}

.signIn_Inner {
    flex-direction: column;
    width: 98vw;
    max-width: 600px;
    text-align: center;
    grid-gap: 12px;
    gap: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.signIn_Logo {
    width: 200px;
}

.signIn_Inner p {
    font-weight: 400;
    font-size: 14px;
    color: white;
    padding-top: 40px;
    padding-bottom: 26px;
}

.signIn_btn {
    width: 350px;
    padding: 16px 32px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    flex-direction: row;
    cursor: pointer;
}

.signIn_btn span {
    font-weight: 600;
    font-size: 14px;
}

.google_btn {
    background: white;
    color: #181818;
}

.apple_btn {
    background: #181818;
    color: white;
}

.twitter_btn {
    background: #1DA1F2;
    color: white;
}

.facebook_btn {
    background: #3B5998;
    color: white;
}

