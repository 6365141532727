* {
  margin: 0;
  padding: 0;
}

.tags {
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}

.tag {
  padding: 7px 10px;
  background: rgba(255,255,255,0.2);
  color: rgba(255,255,255,0.8);
  font-weight: 800;
  font-size: 10px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

.root, .App {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  scroll-snap-align: start;
}

.App img {
  width: 100%;
  object-fit: cover;
  height: calc(var(--vh, 1vh) * 100);
}

.App .overlay {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, rgba(0,0,0,0.80) 90%);
  position: absolute;
  top: 0;
  left: 0;
}

.App .text {
  position: absolute;
  bottom: 80px;
  left: 0;
  width: 100%;
  padding: 10px 20px;
}

.App .text h2 {
  color: white;
  padding-bottom: 10px;
}

.App .text p {
  color: white;
  font-size: 14px;
  width: 80%;
}

.App .text .svg {
  position: absolute;
  top: 10px;
  right: 20px;
  opacity: 0.7;
}

.App .studio_div {
  padding-top: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.App .studio_div .span {
  color: white;
  font-size: 12px;
}

.App .studio_div .studio {
  display: flex;
  align-items: center;
  gap: 6px
}

.App .studio_div .studio img {
  width: 25px;
  height: 25px;
}

.App .studio_div .studio span {
  color: white;
  font-weight: 500;
  font-size: 14px;
}

.studio_followbtn_borded {
  background: transparent;
  border-radius: 51px;
  padding: 10px 16px;
  color: white;
  border: 0.4px solid white;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}