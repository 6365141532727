.bottomBar {
    position: fixed;
    width: 100vw;
    height: 70px;
    background-color: rgba(0,0,0,0.8);
    z-index: 999;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    align-items: center;
    backdrop-filter: blur(6px);
}

.bottomBar img {
    width: 35px;
    height: 35px;
}